
import { Vue, Prop, Component } from "vue-property-decorator";
// @ts-ignore-start
import colores from "@/scss/colores.scss";
// @ts-ignore-end

@Component({
  components: {
    colores
  }
})
export default class MyRequestsTable extends Vue {
  private icon: boolean = false;
  private quality = 0;
  private submitResult: any;
  private idTicket: any;
  private idEvaluacion: any;
  private can_valuate: boolean = false;
  private text = "";
  private url_frontoffice = process.env.VUE_APP_FRONT_OFFICE_URL as string;

  private disabled = false;
  private columns = [
    {
      name: "ticket",
      required: true,
      label: "Ticket",
      field: "id",
      align: "center",
      sortable: true
    },
    {
      name: "date",
      required: true,
      label: "Fecha y Hora",
      field: "date",
      align: "center",
      sortable: true
    },
    {
      name: "service",
      align: "center",
      label: "Servicio",
      field: "service",
      sortable: true
    },
    {
      name: "status",
      label: "Estado",
      field: "status",
      align: "center",
      sortable: true
    },
    {
      name: "details",
      label: "Detalle",
      align: "center",
      sortable: false
    }
  ];

  private tickets = [];
  finish_loading: any = null;

  private mounted() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("tickets/listar/" + this.$store.state.user.user_id, {
        headers: {
          Authorization: "Bearer " + this.$store.state.user.auth_token
        }
      })
      .then(response => {
        this.tickets = response.data as any;

        if (this.tickets.length > 0) {
          this.$q.loading.hide();
          this.finish_loading = true;
          this.$emit("visible", {
            visible: this.finish_loading
          });
        } else {
          this.$q.loading.hide();
          this.finish_loading = false;
          this.$emit("visible", {
            visible: this.finish_loading
          });
        }
      })
      .catch(error => {
        this.finish_loading = false;
        this.$q.loading.hide();
      });
  }

  private routerGo() {
    window.open(this.url_frontoffice, "_self");
  }

  private encuesta(id: any) {
    this.idTicket = id;
    this.icon = true;
  }

  protected onSubmit(evt: any) {
    const formData = new FormData(evt.target);
    const submitResult = [];

    for (const [name, value] of formData.entries()) {
      submitResult.push({
        name,
        value
      });
    }

    this.submitResult = submitResult;
    if (this.submitResult[0].value == 0) {
      this.$q.notify({
        message: "Debe seleccionar una calificación",
        color: "principal",
        position: "top",
        timeout: 5000
      });
    } else {
      this.$axios
        .post("evaluacion-tickets", {
          id_evaluacion: parseInt(this.submitResult[0].value),
          id_ticket: this.idTicket,
          comentario: this.text
        })
        .then(response => {
          this.$router.go(0);
          this.$q.notify({
            message: "Se evaluó el servicio correctamente",
            color: "principal",
            position: "top",
            timeout: 5000
          });
          this.icon = false;
        })
        .catch(error => {
          this.$q.notify({
            message: "Ha ocurrido un error al evaluar el servicio",
            color: "principal",
            position: "top",
            timeout: 5000
          });
        });
    }
  }

  protected sendValuation(evt: any) {
    const formData = new FormData(evt.target);
  }

  private closeDialog() {
    this.icon = false;
    this.text = "";
    this.quality = 0;
  }

  private canValuate(ticket: any) {
    if (ticket.estado_ticket === 6) {
      this.$axios
        .get("evaluacion-tickets/count", {
          params: {
            where: {
              id_ticket: 1
            }
          }
        })
        .then(response => {
          if (response.data.count === 0) {
            this.can_valuate = true;
          } else {
            this.can_valuate = false;
          }
        })
        .catch(error => {
          this.$q.notify({
            message: "Ha ocurrido un error al evaluar el servicio",
            color: "principal",
            position: "top",
            timeout: 5000
          });
        });
    }
  }

  private getPaginationLabel(
    firstRowIndex: number,
    endRowIndex: number,
    totalRowsNumber: number
  ) {
    return firstRowIndex + " - " + endRowIndex + " de " + totalRowsNumber;
  }

  private getTextColor(status: number) {
    let color = "";
    switch (status) {
      case 1:
        color = colores.color_estado_uno;
        break;
      case 2:
        color = colores.color_estado_dos;
        break;
      case 3:
        color = colores.color_estado_tres;
        break;
      case 4:
        color = colores.color_estado_diez;
        break;
      case 5:
        color = colores.color_estado_cinco;
        break;
      case 8:
        color = colores.color_estado_once;
        break;
      case 6:
        color = colores.color_estado_seis;
        break;
      case 9:
        color = colores.color_estado_siete;
        break;
      case 10:
        color = colores.color_estado_tres;
        break;
      default:
        color = "";
        break;
    }
    return color;
  }

  private getBtnColor(status: number) {
    let color = "";
    switch (status) {
      case 1:
        color = colores.color_estado_uno;
        break;
      case 2:
        color = colores.color_estado_dos;
        break;
      case 3:
        color = colores.color_estado_tres;
        break;
      case 4:
        color = colores.color_estado_diez;
        break;
      case 5:
        color = colores.color_estado_cinco;
        break;
      case 8:
        color = colores.color_estado_once;
        break;
      case 6:
        color = colores.color_estado_seis;
        break;
      case 9:
        color = colores.color_estado_siete;
        break;
      case 10:
        color = colores.color_estado_tres;
        break;
      default:
        color = "";
        break;
    }
    return color;
  }

  private humanDate(date: any) {
    return (
      date.split("")[8] +
      date.split("")[9] +
      "-" +
      date.split("")[5] +
      date.split("")[6] +
      "-" +
      date.split("")[0] +
      date.split("")[1] +
      date.split("")[2] +
      date.split("")[3]
    );
  }

  private getStatusName(state: number) {
    let status_name = "Desconocido";
    switch (state) {
      case 1:
        status_name = "Gestión";
        break;
      case 2:
        status_name = "Revisión";
        break;
      case 3:
        status_name = "Derivado";
        break;
      case 4:
        status_name = "Para VB Jefe";
        break;
      case 8:
        status_name = "Con VB Director";
        break;
      case 5:
        status_name = "VB Director";
        break;
      case 6:
        status_name = "Cerrado";
        break;
      case 9:
        status_name = "Despachado";
        break;
      case 10:
        status_name = "Novedad";
        break;
      case 13:
        status_name = "En Espera";
        break;
      default:
        status_name = "Desconocido";
        break;
    }
    return status_name;
  }
}
