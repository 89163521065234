
import { Mixins, Component } from "vue-property-decorator";
// @ts-ignore-start
import colores from "@/scss/colores.scss";
import LabelHelpers from "@/mixins/LabelHelpers";
// @ts-ignore-end

@Component({
  components: {
    colores
  }
})
export default class MyRequestList extends Mixins(LabelHelpers) {
  private tickets = [];
  finish_loading: any = null;

  private hello: Boolean = false;

  private created() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("tickets/listar/" + this.$store.state.user.user_id, {
        headers: {
          Authorization: "Bearer " + this.$store.state.user.auth_token
        }
      })
      .then(response => {
        this.tickets = response.data as any;

        if (this.tickets.length > 0) {
          this.finish_loading = true;
          this.$emit("visible", {
            visible: this.finish_loading
          });
        } else {
          this.finish_loading = false;
          this.$emit("visible", {
            visible: this.finish_loading
          });
        }
      })
      .catch(error => {
        this.finish_loading = false;
      })
      .finally(() => {
        this.$q.loading.hide();
      });
  }

  /*private getTextColor(status: number) {
    let color = "";
    switch (status) {
      case 1:
        color = colores.color_estado_uno;
        break;
      case 2:
        color = colores.color_estado_dos;
        break;
      case 3:
        color = colores.color_estado_tres;
        break;
      case 4:
        color = colores.color_estado_diez;
        break;
      case 5:
        color = colores.color_estado_cinco;
        break;
      case 6:
        color = colores.color_estado_seis;
        break;
      case 9:
        color = colores.color_estado_siete;
        break;
      case 10:
        color = colores.color_estado_tres;
        break;
      default:
        color = "";
        break;
    }
    return color;
  }

  private getBtnColor(status: number) {
    let color = "";
    switch (status) {
      case 1:
        color = colores.color_estado_uno;
        break;
      case 2:
        color = colores.color_estado_dos;
        break;
      case 3:
        color = colores.color_estado_tres;
        break;
      case 4:
        color = colores.color_estado_diez;
        break;
      case 5:
        color = colores.color_estado_cinco;
        break;
      case 6:
        color = colores.color_estado_seis;
        break;
      case 9:
        color = colores.color_estado_siete;
        break;
      case 10:
        color = colores.color_estado_tres;
        break;
      case 15:
        color = colores.color_primario_dos;
        break;
      default:
        color = "";
        break;
    }
    return color;
  }

  private getStateColor(status: number) {
    let color = "";
    switch (status) {
      case 1:
        color = colores.color_estado_uno;
        break;
      case 2:
        color = colores.color_estado_dos;
        break;
      case 3:
        color = colores.color_estado_tres;
        break;
      case 4:
        color = colores.color_estado_diez;
        break;
      case 5:
        color = colores.color_estado_cinco;
        break;
      case 6:
        color = colores.color_estado_seis;
        break;
      case 9:
        color = colores.color_estado_siete;
        break;
      case 10:
        color = colores.color_estado_tres;
        break;
      case 13:
        color = colores.color_estado_diez;
        break;
      case 15:
        color = colores.color_primario_dos;
        break;
      case 16:
        color = colores.color_estado_catorce;
        break;
      default:
        color = "";
        break;
    }
    return color;
  }*/

  private humanDate(date: any) {
    return (
      date.split("")[8] +
      date.split("")[9] +
      "-" +
      date.split("")[5] +
      date.split("")[6] +
      "-" +
      date.split("")[0] +
      date.split("")[1] +
      date.split("")[2] +
      date.split("")[3]
    );
  }
}
